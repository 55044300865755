<template>
  <div class="container donation-card-container">
    <div class="d-flex">
      <div class="col-12">
        <h3>Make a Donation</h3>
        <div class="donate-custom-amount">
          <button @click="$emit('goToCards', 'donate-custom-amount')">Custom Amount</button>
        </div>
        <div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 table-item" v-for="item in fundraising" :key="item.id">
              <!-- BEGIN TABLE -->
              <div class="table-default table1">
                <div class="donation-card-btn">
                  <button @click="goToPay(item)">Donate</button>
                </div>
                <!-- BEGIN TABLE HEADER -->
                <div class="table__header">
                  <p :data-price="item.price" class="table__header--price">
                    <span>$</span>
                    {{item.price}}
                  </p>
                </div>
                <!-- END TABLE HEADER -->
                <div class="table__content">
                  <h4>{{item.title}}</h4>
                  <!-- BEGIN TABLE LIST -->
                  <div>{{item.description}}</div>
                  <!-- END TABLE LIST -->
                </div>
                <!-- END TABLE FOOTER -->
              </div>
              <!-- END TABLE -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MakeADonation",
    props: ['fundraising'],
    methods: {
      goToPay(item) {
        this.$router.push(`/donate/${item.price}`)
      }
    }
  }
</script>

<style scoped lang="scss">
  .donation-card-container {
    padding: 60px 0;

    h3 {
      font-size: 50px;
      margin-bottom: 20px;
      text-align: center;
      color: #fff;
      position: relative;
      z-index: 1;
    }
  }

  .donate-custom-amount {
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      background: #7a6ef0;
      border: 2px solid #8081f2;
      padding: 7px 15px;
      border-radius: 5px;
      font-weight: 600;
      color: #fff;
    }
  }

  .table1 {
    position: relative;
    color: #fff;
    margin-top: 80px;
    z-index: 1;
  }

  .table-item {
    margin-bottom: 25px;
    .table-default {
      background-color: #fff;
      text-align: center;
      padding: 0 14px;
      border-radius: 8px;
      overflow: hidden;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      position: relative;

      .donation-card-btn {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7c71f1de;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        transition: 0.5s;

        button {
          border: 2px solid #fff;
          background: transparent;
          color: #fff;
          padding: 5px 20px;
          font-weight: 600;
          border-radius: 5px;
        }
      }

      &:hover .donation-card-btn {
        visibility: visible;
        opacity: 1;
      }

      h4 {
        color: #fff;
        font-weight: 600;
        font-size: 21px;
      }
    }

    &:nth-child(1) .table-default {
      background: #e878d4;
      background: linear-gradient(-52deg, #877cf4, #877cf4, #31bfd7);
    }

    &:nth-child(2) .table-default {
      background: #e878d4;
      background: linear-gradient(91deg, #877cf4, #e67a7a);
    }

    &:nth-child(3) .table-default {
      background: #e878d4;
      background: linear-gradient(113deg, #39bada, #8a7bf0);
    }

    &:nth-child(4) .table-default {
      background: #e878d4;
      background: linear-gradient(125deg, #7b86ec, #bc7cb2, #e3797d);
    }

    .table-default {
      background: #e878d4;
      background: linear-gradient(125deg, #7b86ec, #bc7cb2, #e3797d);
    }
  }

  .table1 .table__header--title {
    position: relative;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 36px;
  }

  .table1 .table__header--title::after {
    content: ' ';
    position: absolute;
    bottom: -6px;
    left: 0;
    right: 0;
    width: 16px;
    height: 3px;
    background-color: #fff;
    border-radius: 10px;
    margin: auto;
  }

  .table1 .table__header--price {
    font-size: 46px;
    font-weight: 700;
    color: #fff;
  }

  .table__header--price {
    position: relative;
  }

  .table1 .table__header--price span {
    position: relative;
    top: -20px;
    right: -10px;
    font-size: 0.45em;
  }

  .table1 .table__header--price::after {
    content: attr(data-price);
    position: absolute;
    top: -30px;
    left: 30px;
    width: 100%;
    color: inherit;
    font-size: 90px;
    opacity: 0.1;
  }

  .grad2 {
    background: #ffafbd;
    background: -webkit-linear-gradient(220deg, #9dd6c4, #7835b7);
    background: -o-linear-gradient(220deg, #9dd6c4, #7835b7);
    background: linear-gradient(237deg, #9dd6c4, #7835b7);
  }

  .grad3 {
    background: #ffafbd;
    background: -webkit-linear-gradient(220deg, #51a8c2, #a3dac4);
    background: -o-linear-gradient(220deg, #51a8c2, #a3dac4);
    background: linear-gradient(230deg, #51a8c2, #a3dac4);
  }

  @media (max-width: 767px) {
    .donation-card-container h3 {
      font-size: 35px;
    }
  }
</style>
