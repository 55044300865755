<template>
  <div id="donate">
    <!-- Start Page Title Area -->
    <div class="page-title-area donate-bg" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="page-title-content">
          <h2>Become a Partner in Peace</h2>
          <ul>
            <li class="active">Make an Impact</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Page Title Area -->

    <!-- Start About Area -->
    <section id="about" class="about-section ptb-50" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-area-content">
              <h3>Invest In Youth & Nobel Peace Laureates</h3>
              <p>Support PeaceJam's Work Around the World and Help Create a Brighter Future For Us All</p>
            </div>

            <div class="my-4">
              Open the door to PeaceJam travel and adventures with our Nobels and youth as a monthly Partner in Peace donor.  For more contact
              <a href="mailto:info@peacejam.org">info@peacejam.org</a>
            </div>

            <div class="bg-color-box">
              PeaceJam is a tax deductible 501(c)(3) organization and United Nations NGO
            </div>

            <div class="mt-5 mb-4 mb-lg-0 d-flex align-items-sm-start align-items-center flex-column flex-sm-row justify-content-between">
              <img src="../../assets/img/unnamed.png" width="280" alt="">
              <img src="../../assets/img/four-star-charity_navigator-480w.jpg" width="200" alt="">
            </div>
          </div>

          <div class="col-lg-5 offset-lg-1">
            <div class="donate-boxes">
              <div class="donate-box-item">
                <p>Donate with PayPal</p>
                <button @click="openPaypalLink">Donate</button>
                <img src="../../assets/img/pay-pal.png" alt="">
              </div>
              <div class="donate-box-item">
                <p>Donate by Credit card</p>
                <div class="d-flex flex-column align-items-start">
                  <button  @click="goToCards('fundraising')">Donate</button>
                </div>
                <img src="../../assets/img/visa.png" alt="">
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button class="donate-buttons" @click="$modal.show('by-check')">Donate By Check</button>
              <router-link to="/donor-privacy" target="_blank" rel="noreferrer" class="donate-buttons gray-btn">Donor Privacy</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div id="fundraising">
      <div class="fundraising-section">
        <MakeADonation :fundraising="fundraising_data" @goToCards="goToCards" />
      </div>
    </div>

    <div class="about-section ptb-50 donate-custom-amount" id="donate-custom-amount">
      <div class="container">
        <div class="row">
          <div class="col-md-12 contact-area">
            <div class="text-center">
              <h3 class="mb-1">Make a Customized Donation Here</h3>
              <p class="mb-5" style="font-size: 17px;">Set your amount and then choose which department shall receive the donation</p>

              <div class="row">
                <div class="col-md-6">
                  <div class="pay-form contact-wrap">
                    <DonateForm :custom="true"/>
                  </div>
                </div>
                <div class="col-md-6 d-flex align-items-center justify-content-center">
                  <img src="../../assets/img/charity.png" width="300" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal name="by-check" height="auto" width="600px">
      <div class="text-center p-5">
        <h3>Check Donations</h3>

        <br>

        <strong>To make a payment by check send to:</strong>

        <br><br>

        <p style="font-size: 18px">
          PeaceJam Foundation <br>
          1031 33rd Street, Suite 174<br>
          Denver CO 80205 <br>
        </p>

        <p>Specify in memo for targeted donations (i.e. Programs, NWI, Films, Billion Acts, etc) </p>
      </div>
    </modal>
  </div>
</template>

<script>
  import api from "../../services/api";
  import $ from "jquery";
  import useSWRV from "swrv";
  import MakeADonation from "../../components/MakeADonation";
  import DonateForm from "../../components/DonateForm";
  import fundraising_data from "./fundraising_data.json"

  export default {
    name: "Donate",
    data() {
      return {
        fundraising_data
      }
    },
    setup() {
      let {
        data: locations_data,
        error: locations_error,
      } = useSWRV(() => `items/locations?status=published&fields=id,title`, api.fetcher);

      return {
        locations_data,
        locations_error,
      }
    },
    async mounted() {
      window.scrollTo(0, 0);

      $('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
      $('.tab ul.tabs li a').on('click', function (g) {
        var tab = $(this).closest('.tab'),
            index = $(this).closest('li').index();
        tab.find('ul.tabs > li').removeClass('current');
        $(this).closest('li').addClass('current');
        tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
        tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
        g.preventDefault();
      });
    },
    components: {
      DonateForm,
      MakeADonation,
    },
    methods: {
      openPaypalLink() {
        window.open('https://paypal.me/PeaceJamFoundation', '_blank');
      },
      goToCards(element) {
        document.getElementById(element).scrollIntoView({behavior: 'smooth', block: 'start'})
      }
    }
  }
</script>

<style scoped lang="scss">
  .donate-boxes {
    margin-bottom: 40px;

    .donate-box-item {
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      transition: .5s;
      padding: 20px;
      position: relative;

      img {
        position: absolute;
        right: 20px;
        bottom: 20px;
        width: 130px;
        border-radius: 10px;
      }

      button {
        background: transparent;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
        transition: 0.3s;
      }

      p {
        font-size: 18px;
        color: #ffffff;
      }

      &:first-child {
        background: linear-gradient(315deg, #243b7f 0%, #159ad5 100%);

        button {
          border: 2px solid #ffffff;
          color: #0f53a8;
          background: #f7f7f7;
        }
      }

      &:last-child {
        background: linear-gradient(315deg, #00579f 0%, #34bdd5 100%);

        button {
          border: 2px solid #ffffff;
          color: #237da5;
          background: #f7f7f7;
        }
      }
    }
  }
  #about.about-section {
    background-image: none;
  }
  #fundraising {
    padding-top: 92px;
    margin-top: -92px;
  }
  .fundraising-section {
    background-image: url('../../assets/img/youth_at_rally_with_sign.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  #donate-custom-amount {
    padding-top: 142px;
    margin-top: -92px;
  }

  .donate-buttons {
    display: inline-block;
    position: relative;
    padding: 10px 25px;
    font-size: 15px;
    color: #ffffff;
    background-color: #877cf4;
    border: none;
    margin-right: 15px;

    &.gray-btn {
      background: #fdc540;
      color: #fff;
    }
  }

  .donate-bg {
    background-image: url("../../assets/img/Rigoberta_and_Brian_with_youth.jpeg");
    background-size: cover;
    background-position: center 67%;
    position: relative;

    >div {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #0000001f;
    }
  }

  .bg-color-box {
    background: aliceblue;
    padding: 10px;
    margin-bottom: 15px;
  }

  .donate-by-check {
    background: #7c71f1;
    color: #fff;
    border: none;
    padding: 10px 25px;
    border-radius: 50px;
  }

  @media (max-width: 992px) {
    #donate-custom-amount {
      padding-top: 126px;
      margin-top: -76px;
    }
    #fundraising {
      padding-top: 76px;
      margin-top: -76px;
    }
  }
</style>
